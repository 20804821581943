import React, {
    useState,
    useEffect,
    useReducer,
    useCallback,
    useContext,
} from "react";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import {
    Typography,
    TableContainer,
} from "@material-ui/core";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import RatingModal from "../../components/RatingModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

import Pagination from "@material-ui/lab/Pagination";
import { socketManager } from "../../context/Socket/SocketContext";

const reducer = (state, action) => {
    if (action.type === "LOAD_RATINGS") {
        return action.payload;
    }


    if (action.type === "UPDATE_RATINGS") {
        const rating = action.payload;
        const ratingIndex = state.findIndex((s) => s.id === rating.id);

        if (ratingIndex !== -1) {
            state[ratingIndex] = rating;
            return [...state];
        } else {
            return [rating, ...state];
        }
    }

    if (action.type === "DELETE_TAG") {
        const ratingId = action.payload;

        const ratingIndex = state.findIndex((s) => s.id === ratingId);
        if (ratingIndex !== -1) {
            state.splice(ratingIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    tableContainer: {
        boxShadow: "0 1px 4px 0 rgba(0,0,0,0.14)",
        borderRadius: "6px",
        overflow: "hidden",
    },
    table: {
        minWidth: 650,
    },
    tableHeaderCell: {
        fontWeight: 600,
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "0.75rem",
        textTransform: "uppercase",
        padding: "16px",
    },
    avatar: {
        width: 36,
        height: 36,
        marginRight: theme.spacing(2),
    },
    name: {
        fontWeight: 500,
        fontSize: "0.875rem",
        lineHeight: 1.5,
    },
    email: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "0.75rem",
    },
    function: {
        fontSize: "0.875rem",
        lineHeight: 1.5,
    },
    subFunction: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "0.75rem",
    },
    statusChip: {
        borderRadius: "12px",
        padding: "3px 10px",
        fontSize: "0.75rem",
        fontWeight: 500,
        textTransform: "uppercase",
        display: "inline-block",
    },
    onlineChip: {
        backgroundColor: "rgba(76, 175, 80, 0.1)",
        color: "#4CAF50",
    },
    offlineChip: {
        backgroundColor: "rgba(158, 158, 158, 0.1)",
        color: "#9E9E9E",
    },
    editButton: {
        textTransform: "capitalize",
        fontSize: "0.75rem",
        padding: "5px 15px",
    },
    tableCell: {
        padding: "16px",
    },
    headerContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "15px",
        backgroundColor: "#2196f3",
        color: "#fff",
        borderRadius: "3px",
        boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(33, 150, 243,.4)",
        marginBottom: "16px",
        zIndex: 3,
    },
    headerTitle: {
        fontSize: "1.125rem",
        fontWeight: 300,
        marginBottom: 16,
        display: "inline-block",
    },
    newButton: {
        marginLeft: 'auto',
        textTransform: "capitalize",
        fontSize: "0.75rem",
        padding: "5px 15px",
        backgroundColor: "#fff",
        boxShadow: theme.shadows[10]
    },
    customTableCell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const Ratings = () => {
    const classes = useStyles();

    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRatings, setTotalRatings] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [selectedRating, setSelectedRating] = useState(null);
    const [deletingRating, setDeletingRating] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [ratings, dispatch] = useReducer(reducer, []);
    const [ratingModalOpen, setRatingModalOpen] = useState(false);

    const fetchRatings = useCallback(async () => {
        try {
            const { data } = await api.get("/ratings/", {
                params: { searchParam, pageNumber },
            });
            dispatch({ type: "LOAD_RATINGS", payload: data.ratings });
            setTotalRatings(data.count);
            setTotalPages(Math.ceil(data.count / 20));
            setHasMore(data.hasMore);
            setLoading(false);
        } catch (err) {
            toastError(err);
        }
    }, [searchParam, pageNumber]);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            fetchRatings();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber, fetchRatings]);

    useEffect(() => {
        const socket = socketManager.getSocket(user.companyId);


        socket.on("user", (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_RATINGS", payload: data.ratings });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_USER", payload: +data.ratingId });
            }
        });

        return () => {
            // //socket.off("user", (data) => {
            //     if (data.action === "update" || data.action === "create") {
            //         dispatch({ type: "UPDATE_RATINGS", payload: data.ratings });
            //     }

            //     if (data.action === "delete") {
            //         dispatch({ type: "DELETE_USER", payload: +data.ratingId });
            //     }
            // });
        };
    }, [user]);

    const handleOpenRatingModal = () => {
        setSelectedRating(null);
        setRatingModalOpen(true);
    };

    const handleCloseRatingModal = () => {
        setSelectedRating(null);
        setRatingModalOpen(false);
    };

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleEditRating = (rating) => {
        setSelectedRating(rating);
        setRatingModalOpen(true);
    };

    const handleDeleteRating = async (ratingId) => {
        try {
            await api.delete(`/ratings/${ratingId}`);
            toast.success(i18n.t("ratings.toasts.deleted"));
        } catch (err) {
            toastError(err);
        }
        setDeletingRating(null);
        setSearchParam("");
        setPageNumber(1);

        dispatch({ type: "RESET" });
        setPageNumber(1);
        await fetchRatings();
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };


    const handlePageChange = (event, value) => {
        setPageNumber(value);
    };

    return (
        <div className={classes.root}>
            <>
                <ConfirmationModal
                    title={deletingRating && `${i18n.t("ratings.confirmationModal.deleteTitle")}`}
                    open={confirmModalOpen}
                    onClose={setConfirmModalOpen}
                    onConfirm={() => handleDeleteRating(deletingRating.id)}
                >
                    {i18n.t("ratings.confirmationModal.deleteMessage")}
                </ConfirmationModal>
                <RatingModal
                    open={ratingModalOpen}
                    onClose={handleCloseRatingModal}
                    reload={fetchRatings}
                    aria-labelledby="form-dialog-title"
                    ratingId={selectedRating && selectedRating.id}
                />
            </>
            <Paper className={classes.tableContainer}>
                <div className={classes.headerContainer}>
                    <Typography variant="h6" className={classes.headerTitle}>
                        {i18n.t("ratings.title")} ({ratings.length})
                    </Typography>
                    <Button variant="outlined" color="primary" onClick={handleOpenRatingModal} className={classes.newButton}>
                        {i18n.t("ratings.buttons.add")}
                    </Button>
                </div>
                <TableContainer>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeaderCell} align="center" >{i18n.t("ratings.table.name")}</TableCell>
                                <TableCell className={classes.tableHeaderCell} align="center" >{i18n.t("ratings.table.geral")}</TableCell>
                                <TableCell className={classes.tableHeaderCell} align="center">{i18n.t("ratings.table.actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ratings.map((author, index) => (
                                <TableRow key={author.id}>
                                    <TableCell className={classes.tableCell} align="center">
                                        <Typography className={classes.email}>{author.name}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {author.geral === true ? (
                                            <CheckCircleIcon style={{ color: 'green' }} />
                                        ) : (
                                            ''
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton size="small" onClick={() => handleEditRating(author)}>
                                            <EditIcon />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                setConfirmModalOpen(true);
                                                setDeletingRating(author);
                                            }}
                                        >
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Pagination
                    count={totalPages}
                    page={pageNumber}
                    onChange={handlePageChange}
                    color="primary"
                    style={{ marginTop: "50px", display: "flex", justifyContent: "center" }}
                />
            </Paper>
        </div>
    );
};

export default Ratings;
