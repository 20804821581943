import { CircularProgress } from "@material-ui/core";
import { Accordion, AccordionDetails, AccordionSummary, Box, Dialog, DialogTitle, Stack, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@mui/material/colors";
import React, { useState } from "react";
import { useEffect } from "react"
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },

    btnWrapper: {
        position: "relative"
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    centeredContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%' // Adjust height as needed
    }
}));



const FlowBuilderTestRequest = ({ open, data, close }) => {

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [activeModal, setActiveModal] = useState(false);
    const [dataWebhook, setDataWebHook] = useState([]);

    useEffect(() => {
        if (!open) return
        const fetchData = async () => {
            setLoading(true);
            try {

                const response = await api.post('/httpRequestTest', data.data)
                setDataWebHook(response)
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
        setActiveModal(true);
        fetchData();

    }, [open]);

    const formatApiResponse = (response) => {
        return Object.entries(response).map(([key, value]) => (
            <Accordion key={key}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.centeredContent}
                >
                    <Typography>{key}</Typography>

                </AccordionSummary>
                <AccordionDetails  className={classes.centeredContent}>

                    {Array.isArray(value) ? (
                        <ul>
                            {value.map((item, index) => (
                                <li key={index}>{JSON.stringify(item, null, 2)}</li>
                            ))}
                        </ul>
                    ) : (
                        <pre>{JSON.stringify(value, null, 2)}</pre>
                    )}
                </AccordionDetails>
            </Accordion>
        ));
    };

    const handleClose = () => {
        close(null);
        setActiveModal(false);
    };

    return (
        <div className={classes.root}>

            <Dialog
                open={activeModal}
                onClose={handleClose}
                fullWidth="md"
                scroll="paper"
            >
                {loading && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100vh'
                        }}
                    >
                        <CircularProgress />
                        <Typography sx={{ ml: 2 }}>Realizando Requisição</Typography>
                    </Box>
                )}

                {(!loading && data && dataWebhook) && (
                    <Dialog open={true} onClose={handleClose}>
                        <DialogTitle id="teste">Retorno Requisição</DialogTitle>
                            {formatApiResponse(dataWebhook)}
                    </Dialog>
                )}
            </Dialog>
        </div>
    );

}


export default FlowBuilderTestRequest