import React, {
  useState,
  useEffect,
  useCallback
} from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import api from "../../services/api";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import toastError from "../../errors/toastError";
import {
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Stack,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Tooltip
} from "@mui/material";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Box, CircularProgress, Popover } from "@material-ui/core";
import messageNode from "./nodes/messageNode.js";
import "reactflow/dist/style.css";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge
} from "react-flow-renderer";
import FlowBuilderAddTextModal from "../../components/FlowBuilderAddTextModal";
import FlowBuilderIntervalModal from "../../components/FlowBuilderIntervalModal";
import startNode from "./nodes/startNode";
import conditionNode from "./nodes/conditionNode";
import menuNode from "./nodes/menuNode";
import intervalNode from "./nodes/intervalNode";
import imgNode from "./nodes/imgNode";
import randomizerNode from "./nodes/randomizerNode";
import queueNode from "./nodes/queueNode.js";
import tagNode from "./nodes/tagNode.js";
import videoNode from "./nodes/videoNode";
import FlowBuilderConditionModal from "../../components/FlowBuilderConditionModal";
import FlowBuilderMenuModal from "../../components/FlowBuilderMenuModal";
import {
  AccessTime,
  CallSplit,
  DynamicFeed,
  LibraryBooks,
  RocketLaunch,
  MoreVert
} from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CameraRollIcon from '@material-ui/icons/CameraRoll';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ContactsIcon from '@mui/icons-material/Contacts';
import RemoveEdge from "./nodes/removeEdge";
import FlowBuilderAddImgModal from "../../components/FlowBuilderAddImgModal";
import FlowBuilderAddAudioModal from "../../components/FlowBuilderAddAudioModal";
import audioNode from "./nodes/audioNode";
import { useNodeStorage } from "../stores/useNodeStorage.js"
import FlowBuilderRandomizerModal from "../../components/FlowBuilderRandomizerModal";
import FlowBuilderAddVideoModal from "../../components/FlowBuilderAddVideoModal";
import FlowBuilderAddDocumentModal from "../../components/FlowBuilderAddDocumentModal";
import FlowBuilderSingleBlockModal from "../../components/FlowBuilderSingleBlockModal";
import singleBlockNode from "./nodes/singleBlockNode";
import { colorPrimary } from "../styles/styles";
import FlowBuilderQueuesModal from "../../components/FlowBuilderQueuesModal";
import FlowBuilderAddTagModal from "../../components/FlowBuilderAddTagModal/index.js";
import FlowBuilderAddQuestionModal from "../../components/FlowBuilderAddQuestion/index.js";
import questionNode from "./nodes/questionNode.js";
import collectNode from "./nodes/collectNode.js";
import FlowBuilderAddCollectModal from "../../components/FlowBuilderAddCollect/index.js";
import closeTicketNode from "./nodes/closeTicketNode.js";
import FlowBuilderAddCloseTicket from "../../components/FlowBuilderAddCloseTicket/index.js";
import HttpIcon from '@mui/icons-material/Http';
import httpRequestNode from "./nodes/httpRequestNode.js";
import FlowBuilderHttpRequestModal from "../../components/FlowBuilderHttpRequestModal/index.js";
import updateContactNode from "./nodes/updateContactNode.js";
import FlowBuilderUpdateContactModal from "../../components/FlowBuilderUpdateContactModal/index.js";

const useStyles = makeStyles(theme => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    position: "relative",
    backgroundColor: "#F8F9FA",
    overflowY: "scroll",
    ...theme.scrollbarStyles
  },
  speeddial: {
    backgroundColor: "red"
  },
  actionButton: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1),
    minWidth: 0,
    borderRadius: '50%',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: '10px 16px',
    '& svg': {
      fontSize: '1.2rem',
    },
  },
  menuItemText: {
    fontSize: '0.9rem',
  },
  importExportMenu: {
    padding: theme.spacing(1),
    minWidth: 200,
  }
}));

function geraStringAleatoria(tamanho) {
  var stringAleatoria = "";
  var caracteres =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < tamanho; i++) {
    stringAleatoria += caracteres.charAt(
      Math.floor(Math.random() * caracteres.length)
    );
  }
  return stringAleatoria;
}

const nodeTypes = {
  message: messageNode,
  start: startNode,
  condition: conditionNode,
  menu: menuNode,
  question: questionNode,
  collect: collectNode,
  interval: intervalNode,
  img: imgNode,
  audio: audioNode,
  randomizer: randomizerNode,
  queue: queueNode,
  tag: tagNode,
  video: videoNode,
  singleBlock: singleBlockNode,
  closeTicket: closeTicketNode,
  httpRequest: httpRequestNode,
  updateContact: updateContactNode
};

const edgeTypes = {
  buttonedge: RemoveEdge
};

const initialNodes = [
  {
    id: "1",
    position: { x: 250, y: 100 },
    data: { label: "Inicio do fluxo" },
    type: "start"
  }
];

const initialEdges = [];

export const FlowBuilderConfig = () => {
  const classes = useStyles();
  const { id } = useParams();
  const storageItems = useNodeStorage();
  const [loading, setLoading] = useState(false);
  const [nameFlow, setNameFlow] = useState('')
  const [pageNumber, setPageNumber] = useState(1);
  const [jsonFlow, setJsonFlow] = useState(null)
  const [dataNode, setDataNode] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [importExportAnchorEl, setImportExportAnchorEl] = useState(null);
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [modalAddQuestion, setModalAddQuestion] = useState(null);
  const [modalAddCollect, setModalAddCollect] = useState(null);
  const [modalAddText, setModalAddText] = useState(null);
  const [modalAddInterval, setModalAddInterval] = useState(false);
  const [modalAddCondition, setModalAddCondition] = useState(null);
  const [modalAddCloseTicket, setModalAddCloseTicket] = useState(null);
  const [modalAddQueues, setModalAddQueues] = useState(false);
  const [modalAddTags, setModalAddTags] = useState(false);
  const [modalAddMenu, setModalAddMenu] = useState(null);
  const [modalAddImg, setModalAddImg] = useState(null);
  const [modalAddAudio, setModalAddAudio] = useState(null);
  const [modalAddRandomizer, setModalAddRandomizer] = useState(null);
  const [modalAddVideo, setModalAddVideo] = useState(null);
  const [modalAddDocument, setModalAddDocument] = useState(null);
  const [modalAddHttpRequest, setModalAddHttpRequest] = useState(null);
  const [modalAddSingleBlock, setModalAddSingleBlock] = useState(null);
  const [modalAddUpdateContact, setModalAddUpdateContact] = useState(null);

  const importExportOpen = Boolean(importExportAnchorEl);
  const actionsOpen = Boolean(actionsAnchorEl);

  const handleImportExportClick = (event) => {
    setImportExportAnchorEl(event.currentTarget);
  };

  const handleImportExportClose = () => {
    setImportExportAnchorEl(null);
  };

  const handleActionsClick = (event) => {
    setActionsAnchorEl(event.currentTarget);
  };

  const handleActionsClose = () => {
    setActionsAnchorEl(null);
  };

  const addNode = (type, data) => {
    const posY = nodes[nodes.length - 1].position.y;
    const posX =
      nodes[nodes.length - 1].position.x + nodes[nodes.length - 1].width + 40;
    if (type === "start") {
      return setNodes(old => {
        return [
          ...old.filter(item => item.id !== "1"),
          {
            id: "1",
            position: { x: posX, y: posY },
            data: { label: "Inicio do fluxo" },
            type: "start"
          }
        ];
      });
    }
    if (type === "text") {
      return setNodes(old => {
        return [
          ...old,
          {
            id: geraStringAleatoria(30),
            position: { x: posX, y: posY },
            data: { label: data.text },
            type: "message"
          }
        ];
      });
    }
    if (type === "interval") {
      return setNodes(old => {
        return [
          ...old,
          {
            id: geraStringAleatoria(30),
            position: { x: posX, y: posY },
            data: { label: `Intervalo ${data.sec} seg.`, sec: data.sec },
            type: "interval"
          }
        ];
      });
    }
    if (type === "condition") {
      return setNodes(old => {
        return [
          ...old,
          {
            id: geraStringAleatoria(30),
            position: { x: posX, y: posY },
            data: {
              key: data.key,
              condition: data.condition,
              value: data.value
            },
            type: "condition"
          }
        ];
      });
    }
    if (type === "closeTicket") {
      return setNodes(old => {
        return [
          ...old,
          {
            id: geraStringAleatoria(30),
            position: { x: posX, y: posY },
            data: { label: data.text },
            type: "closeTicket"
          }
        ];
      });
    }
    if (type === "menu") {
      return setNodes(old => {
        return [
          ...old,
          {
            id: geraStringAleatoria(30),
            position: { x: posX, y: posY },
            data: {
              message: data.message,
              arrayOption: data.arrayOption
            },
            type: "menu"
          }
        ];
      });
    }
    if (type === "httpRequest") {
      return setNodes(old => {
        return [
          ...old,
          {
            id: geraStringAleatoria(30),
            position: { x: posX, y: posY },
            data: {
              url: data.url,
              body: data.body,
              method: data.method,
              headers: data.headers,
              params: data.params,
            },
            type: "httpRequest"
          }
        ];
      });
    }
    if (type === "question") {
      return setNodes(old => {
        return [
          ...old,
          {
            id: geraStringAleatoria(30),
            position: { x: posX, y: posY },
            data: { label: data.text, variable: data.variable },
            type: "question"
          }
        ];
      });
    }
    if (type === "collect") {
      return setNodes(old => {
        return [
          ...old,
          {
            id: geraStringAleatoria(30),
            position: { x: posX, y: posY },
            data: { collect: data.collect },
            type: "collect"
          }
        ];
      });
    }
    if (type === "img") {
      return setNodes(old => {
        return [
          ...old,
          {
            id: geraStringAleatoria(30),
            position: { x: posX, y: posY },
            data: { url: data.url },
            type: "img"
          }
        ];
      });
    }
    if (type === "audio") {
      return setNodes(old => {
        return [
          ...old,
          {
            id: geraStringAleatoria(30),
            position: { x: posX, y: posY },
            data: { url: data.url, record: data.record },
            type: "audio"
          }
        ];
      });
    }
    if (type === "document") {
      return setNodes(old => {
        return [
          ...old,
          {
            id: geraStringAleatoria(30),
            position: { x: posX, y: posY },
            data: { url: data.url, record: data.record },
            type: "document"
          }
        ];
      });
    }
    if (type === "queue") {
      return setNodes(old => {
        return [
          ...old,
          {
            id: geraStringAleatoria(30),
            position: { x: posX, y: posY },
            data: { queue: data.queue, queueUsersId: data.queueUsersId, moreOptions: data.moreOptions, checkedUser: data.checkedUser, ativarRoteador: data.ativarRoteador },
            type: "queue"
          }
        ];
      });
    }
    if (type === "tag") {
      return setNodes(old => {
        return [
          ...old,
          {
            id: geraStringAleatoria(30),
            position: { x: posX, y: posY },
            data: { tag: data.tag },
            type: "tag"
          }
        ];
      });
    }
    if (type === "randomizer") {
      return setNodes(old => {
        return [
          ...old,
          {
            id: geraStringAleatoria(30),
            position: { x: posX, y: posY },
            data: { percent: data.percent },
            type: "randomizer"
          }
        ];
      });
    }
    if (type === "video") {
      return setNodes(old => {
        return [
          ...old,
          {
            id: geraStringAleatoria(30),
            position: { x: posX, y: posY },
            data: { url: data.url },
            type: "video"
          }
        ];
      });
    }
    if (type === "singleBlock") {
      return setNodes(old => {
        return [
          ...old,
          {
            id: geraStringAleatoria(30),
            position: { x: posX, y: posY },
            data: { ...data },
            type: "singleBlock"
          }
        ];
      });
    }
    if (type === "updateContact") {
      return setNodes(old => {
        return [
          ...old,
          {
            id: geraStringAleatoria(30),
            position: { x: posX, y: posY },
            data: { ...data },
            type: "updateContact"
          }
        ];
      });
    }
  };

  const textAdd = data => {
    addNode("text", data);
  };

  const intervalAdd = data => {
    addNode("interval", data);
  };

  const conditionAdd = data => {
    addNode("condition", data);
  };

  const closeTicketAdd = data => {
    addNode("closeTicket", data);
  };

  const menuAdd = data => {
    addNode("menu", data);
  };

  const questionAdd = data => {
    addNode("question", data);
  };

  const collectAdd = data => {
    addNode("collect", data);
  };

  const imgAdd = data => {
    addNode("img", data);
  };

  const audioAdd = data => {
    addNode("audio", data);
  };

  const randomizerAdd = data => {
    addNode("randomizer", data);
  };

  const videoAdd = data => {
    addNode("video", data);
  };

  const documentAdd = data => {
    addNode("document", data);
  };

  const queueAdd = data => {
    addNode("queue", data);
  };

  const httpRequestAdd = data => {
    addNode("httpRequest", data);
  };

  const tagAdd = data => {
    addNode("tag", data);
  };

  const singleBlockAdd = data => {
    addNode("singleBlock", data);
  };

  const updateContactAdd = data => {
    addNode("updateContact", data);
  };

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get(`/flowbuilder/flow/${id}`);
          if (data.flow.flow !== null) {
            setNameFlow(data.flow.name)
            setNodes(data.flow.flow.nodes);
            setEdges(data.flow.flow.connections);
          }
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [id]);

  useEffect(() => {
    if (storageItems.action === "delete") {
      setNodes(old => old.filter(item => item.id !== storageItems.node));
      setEdges(old => {
        const newData = old.filter(item => item.source !== storageItems.node);
        const newClearTarget = newData.filter(
          item => item.target !== storageItems.node
        );
        return newClearTarget;
      });
      storageItems.setNodesStorage("");
      storageItems.setAct("idle");
    }
    if (storageItems.action === "duplicate") {
      const nodeDuplicate = nodes.filter(
        item => item.id === storageItems.node
      )[0];
      const maioresX = nodes.map(node => node.position.x);
      const maiorX = Math.max(...maioresX);
      const finalY = nodes[nodes.length - 1].position.y;
      const nodeNew = {
        ...nodeDuplicate,
        id: geraStringAleatoria(30),
        position: {
          x: maiorX + 240,
          y: finalY
        },
        selected: false,
        style: { backgroundColor: "#555555", padding: 0, borderRadius: 8 }
      };
      setNodes(old => [...old, nodeNew]);
      storageItems.setNodesStorage("");
      storageItems.setAct("idle");
    }
  }, [storageItems.action]);

  const loadMore = () => {
    setPageNumber(prevState => prevState + 1);
  };

  const handleScroll = e => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect = useCallback(
    params => setEdges(eds => addEdge(params, eds)),
    [setEdges]
  );

  const saveFlow = async () => {
    try {
      await api.post("/flowbuilder/flow", {
        idFlow: id,
        nodes: nodes,
        connections: edges
      });
      toast.success("Fluxo salvo com sucesso");
    } catch (err) {
      toastError(err);
    }
  };

  const exportFlow = async () => {
    try {
      const { data } = await api.get(`/flowbuilder/exportFlow/${id}`);
      
      const fileName = `${nameFlow}.json`;
      const json = data.flow;
      const blob = new Blob([json], { type: 'application/json' });
      const href = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = href;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(href);
      
      toast.success("Fluxo exportado com sucesso");
    } catch (err) {
      toastError(err);
    }
    
    handleImportExportClose();
  };

  const importFlow = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const contents = e.target.result;
      setJsonFlow(contents);

      try {
        const { data } = await api.put(`/flowbuilder/importFlow/${id}`, {
          jsonFlow: contents
        });
        
        if (data.flow.flow !== null) {
          setNameFlow(data.flow.name);
          setNodes(data.flow.flow.nodes);
          setEdges(data.flow.flow.connections);
          toast.success("Fluxo importado com sucesso");
        }
      } catch (error) {
        toastError(error);
      }

      handleImportExportClose();
    };
    reader.readAsText(file);
  };

  const doubleClick = (event, node) => {
    setDataNode(node);
    if (node.type === "message") {
      setModalAddText("edit");
    }
    if (node.type === "interval") {
      setModalAddInterval("edit");
    }
    if (node.type === "condition") {
      setModalAddCondition("edit");
    }
    if (node.type === "closeTicket") {
      setModalAddCloseTicket("edit");
    }
    if (node.type === "menu") {
      setModalAddMenu("edit");
    }
    if (node.type === "question") {
      setModalAddQuestion("edit");
    }
    if (node.type === "collect") {
      setModalAddCollect("edit");
    }
    if (node.type === "img") {
      setModalAddImg("edit");
    }
    if (node.type === "audio") {
      setModalAddAudio("edit");
    }
    if (node.type === "httpRequest") {
      setModalAddHttpRequest("edit");
    }
    if (node.type === "document") {
      setModalAddDocument("edit");
    }
    if (node.type === "randomizer") {
      setModalAddRandomizer("edit");
    }
    if (node.type === "queue") {
      setModalAddQueues("edit");
    }
    if (node.type === "tag") {
      setModalAddTags("edit");
    }
    if (node.type === "singleBlock") {
      setModalAddSingleBlock("edit");
    }
    if (node.type === "updateContact") {
      setModalAddUpdateContact("edit");
    }
  };

  const clickNode = (event, node) => {
    setNodes(old =>
      old.map(item => {
        if (item.id === node.id) {
          return {
            ...item,
            style: { backgroundColor: "#9a00ed", padding: 1, borderRadius: 8 }
          };
        }
        return {
          ...item,
          style: { backgroundColor: "#13111C", padding: 0, borderRadius: 8 }
        };
      })
    );
  };

  const clickEdge = (event, node) => {
    setNodes(old =>
      old.map(item => {
        return {
          ...item,
          style: { backgroundColor: "#13111C", padding: 0, borderRadius: 8 }
        };
      })
    );
  };

  const updateNode = dataAlter => {
    setNodes(old =>
      old.map(itemNode => {
        if (itemNode.id === dataAlter.id) {
          return dataAlter;
        }
        return itemNode;
      })
    );
    setModalAddText(null);
    setModalAddInterval(null);
    setModalAddMenu(null);
  };

  const actions = [
    {
      icon: <RocketLaunch sx={{ color: "#3ABA38" }} />,
      name: "Inicio",
      type: "start"
    },
    {
      icon: <LibraryBooks sx={{ color: "#EC5858" }} />,
      name: "Conteúdo",
      type: "content"
    },
    {
      icon: <DynamicFeed sx={{ color: "#683AC8" }} />,
      name: "Menu",
      type: "menu"
    },
    {
      icon: <QuestionMarkIcon sx={{ color: "#683AC9" }} />,
      name: "Pergunta",
      type: "question"
    },
    {
      icon: <CropLandscapeIcon sx={{ color: "#683AC9" }} />,
      name: "Capturar",
      type: "collect"
    },
    {
      icon: <ContactsIcon sx={{ color: "#f3f3f3" }} />,
      name: "Atualizar Contato",
      type: "updateContact"
    },
    {
      icon: <CallSplit sx={{ color: "#1FBADC" }} />,
      name: "Randomizador",
      type: "random"
    },
    {
      icon: <CameraRollIcon color="primary" />,
      name: "Departamentos",
      type: "queue"
    },
    {
      icon: <LocalOfferIcon sx={{ color: "#EC5858" }} />,
      name: "Tags",
      type: "tag"
    },
    {
      icon: <FilterAltIcon sx={{ color: "#683AC8" }} />,
      name: "Condicionais",
      type: "condition"
    },
    {
      icon: <CloseIcon sx={{ color: "#683AC8" }} />,
      name: "Encerrar Ticket",
      type: "closeTicket"
    },
    {
      icon: <HttpIcon sx={{ color: "#683AC8" }} />,
      name: "Http Request (Beta)",
      type: "httpRequest"
    },
    {
      icon: <AccessTime sx={{ color: "#F7953B" }} />,
      name: "Intervalo",
      type: "interval"
    }
  ];

  const clickActions = type => {
    handleActionsClose();
    
    switch (type) {
      case "start":
        addNode("start");
        break;
      case "menu":
        setModalAddMenu("create");
        break;
      case "question":
        setModalAddQuestion("create");
        break;
      case "collect":
        setModalAddCollect("create");
        break;
      case "content":
        setModalAddSingleBlock("create");
        break;
      case "updateContact":
        setModalAddUpdateContact("create");
        break;
      case "random":
        setModalAddRandomizer("create");
        break;
      case "queue":
        setModalAddQueues("create");
        break;
      case "httpRequest":
        setModalAddHttpRequest("create");
        break;
      case "tag":
        setModalAddTags("create");
        break;
      case "condition":
        setModalAddCondition("create");
        break;
      case "closeTicket":
        setModalAddCloseTicket("create");
        break;
      case "interval":
        setModalAddInterval("create");
        break;
      default:
    }
  };

  return (
    <Stack sx={{ height: "100vh" }}>
      <FlowBuilderAddTextModal
        open={modalAddText}
        onSave={textAdd}
        data={dataNode}
        onUpdate={updateNode}
        close={setModalAddText}
      />
      <FlowBuilderAddQuestionModal
        open={modalAddQuestion}
        onSave={questionAdd}
        data={dataNode}
        onUpdate={updateNode}
        close={setModalAddQuestion}
      />
      <FlowBuilderAddCollectModal
        open={modalAddCollect}
        onSave={collectAdd}
        data={dataNode}
        variable={nodes}
        onUpdate={updateNode}
        close={setModalAddCollect}
      />
      <FlowBuilderQueuesModal
        open={modalAddQueues}
        onSave={queueAdd}
        data={dataNode}
        onUpdate={updateNode}
        close={setModalAddQueues}
      />
      <FlowBuilderHttpRequestModal
        open={modalAddHttpRequest}
        onSave={httpRequestAdd}
        data={dataNode}
        onUpdate={updateNode}
        close={setModalAddHttpRequest}
      />
      <FlowBuilderAddTagModal
        open={modalAddTags}
        onSave={tagAdd}
        data={dataNode}
        onUpdate={updateNode}
        close={setModalAddTags}
      />
      <FlowBuilderIntervalModal
        open={modalAddInterval}
        onSave={intervalAdd}
        data={dataNode}
        onUpdate={updateNode}
        close={setModalAddInterval}
      />
      <FlowBuilderConditionModal
        open={modalAddCondition}
        onSave={conditionAdd}
        data={dataNode}
        variable={nodes}
        onUpdate={updateNode}
        close={setModalAddCondition}
      />
      <FlowBuilderAddCloseTicket
        open={modalAddCloseTicket}
        onSave={closeTicketAdd}
        data={dataNode}
        onUpdate={updateNode}
        close={setModalAddCloseTicket}
      />
      <FlowBuilderMenuModal
        open={modalAddMenu}
        onSave={menuAdd}
        data={dataNode}
        onUpdate={updateNode}
        close={setModalAddMenu}
      />
      <FlowBuilderAddImgModal
        open={modalAddImg}
        onSave={imgAdd}
        data={dataNode}
        onUpdate={updateNode}
        close={setModalAddImg}
      />
      <FlowBuilderAddAudioModal
        open={modalAddAudio}
        onSave={audioAdd}
        data={dataNode}
        onUpdate={updateNode}
        close={setModalAddAudio}
      />
      <FlowBuilderRandomizerModal
        open={modalAddRandomizer}
        onSave={randomizerAdd}
        data={dataNode}
        onUpdate={updateNode}
        close={setModalAddRandomizer}
      />
      <FlowBuilderAddVideoModal
        open={modalAddVideo}
        onSave={videoAdd}
        data={dataNode}
        onUpdate={updateNode}
        close={setModalAddVideo}
      />
      <FlowBuilderAddDocumentModal
        open={modalAddDocument}
        onSave={documentAdd}
        data={dataNode}
        onUpdate={updateNode}
        close={setModalAddDocument}
      />
      <FlowBuilderSingleBlockModal
        open={modalAddSingleBlock}
        onSave={singleBlockAdd}
        data={dataNode}
        onUpdate={updateNode}
        close={setModalAddSingleBlock}
      />
      <FlowBuilderUpdateContactModal
        open={modalAddUpdateContact}
        onSave={updateContactAdd}
        data={dataNode}
        onUpdate={updateNode}
        close={setModalAddUpdateContact}
      />
      <MainHeader>
        <Title>Desenhe seu fluxo</Title>
      </MainHeader>
      {!loading && (
        <Paper
          className={classes.mainPaper}
          variant="outlined"
          onScroll={handleScroll}
        >
          <Stack
            sx={{
              position: "absolute",
              justifyContent: "center",
              flexDirection: "row",
              width: "100%",
              top: 10
            }}
          >
            <Typography
              style={{ color: "#010101", textShadow: "#010101 1px 0 10px" }}
            >
              Não se esqueça de salvar seu fluxo!
            </Typography>
          </Stack>
          
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
            <Tooltip title="Adicionar elementos">
              <IconButton 
                color="primary"
                onClick={handleActionsClick}
                sx={{ 
                  backgroundColor: "rgb(25, 118, 210)",
                  color: "rgb(255, 255, 255)",
                  '&:hover': { backgroundColor: "rgb(25, 118, 210)" },
                  width: "56px",
                  height: "56px"
                }}
              >
                <SpeedDialIcon />
              </IconButton>
            </Tooltip>
            
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleImportExportClick}
                startIcon={importExportOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                sx={{ textTransform: "none" }}
              >
                Importar/Exportar
              </Button>
              
              <Button
                variant="contained"
                color="primary"
                onClick={saveFlow}
                sx={{ textTransform: "none" }}
              >
                Salvar
              </Button>
            </Stack>
          </Stack>

          {/* Import/Export Menu */}
          <Menu
            id="import-export-menu"
            anchorEl={importExportAnchorEl}
            open={importExportOpen}
            onClose={handleImportExportClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              elevation: 3,
              sx: { borderRadius: '8px' }
            }}
          >
            <div className={classes.importExportMenu}>
              <input
                type="file"
                accept=".json"
                style={{ display: 'none' }}
                onChange={importFlow}
                id="import-json"
              />
              <label htmlFor="import-json">
                <MenuItem component="span" className={classes.menuItem}>
                  <CloudUploadIcon color="primary" />
                  <span className={classes.menuItemText}>Importar</span>
                </MenuItem>
              </label>
              
              <MenuItem onClick={exportFlow} className={classes.menuItem}>
                <CloudDownloadIcon color="primary" />
                <span className={classes.menuItemText}>Exportar</span>
              </MenuItem>
            </div>
          </Menu>

          {/* Actions Menu */}
          <Menu
            id="actions-menu"
            anchorEl={actionsAnchorEl}
            open={actionsOpen}
            onClose={handleActionsClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{
              elevation: 3,
              sx: { 
                borderRadius: '8px',
                maxHeight: '70vh',
                overflowY: 'auto'
              }
            }}
          >
            {actions.map((action) => (
              <MenuItem 
                key={action.name}
                onClick={() => clickActions(action.type)}
                className={classes.menuItem}
              >
                {action.icon}
                <span className={classes.menuItemText}>{action.name}</span>
              </MenuItem>
            ))}
          </Menu>

          <Stack
            direction={"row"}
            style={{
              width: "100%",
              height: "90%",
              position: "relative",
              display: "flex"
            }}
          >
            <ReactFlow
              nodes={nodes}
              edges={edges}
              deleteKeyCode={["Backspace", "Delete"]}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onNodeDoubleClick={doubleClick}
              onNodeClick={clickNode}
              onEdgeClick={clickEdge}
              onConnect={onConnect}
              nodeTypes={nodeTypes}
              fitView
              edgeTypes={edgeTypes}
              variant={"cross"}
              defaultEdgeOptions={{
                style: { color: "#ff0000", strokeWidth: "1px" },
                animated: true
              }}
            >
              <Controls />
              <MiniMap />
              <Background variant="dots" gap={12} size={-1} />
            </ReactFlow>

            <Stack
              style={{
                backgroundColor: "#FAFAFA",
                height: "20px",
                width: "58px",
                position: "absolute",
                bottom: 0,
                right: 0,
                zIndex: 1111
              }}
            />
          </Stack>
        </Paper>
      )}
      {loading && (
        <Stack justifyContent={"center"} alignItems={"center"} height={"70vh"}>
          <CircularProgress />
        </Stack>
      )}
    </Stack>
  );
};