import React, { useState, useEffect, useRef, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, Switch } from "@mui/material";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const selectFieldStyles = {
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "#909090",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#000000",
    borderWidth: "thin",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#9a00ed",
    borderWidth: "thin",
  },
};

const TransferSchema = Yup.object().shape({
  queueId: Yup.string().required("Selecione a fila de destino!"),
});

const FlowBuilderQueuesModal = ({ open, onSave, onUpdate, data, close }) => {
  const classes = useStyles();
  const isMounted = useRef(true);

  const { user } = useContext(AuthContext)

  const [queues, setQueues] = useState([]);
  const [queueId, setQueueId] = useState('');
  const [moreOptions, setMoreOptions] = useState(false);
  const [checkedUser, setCheckedUser] = useState(false);
  const [ativarRoteador, setAtivarRoteador] = useState(false);
  const [userId, setUserId] = useState(0)
  const [usersList, setUsersList] = useState([]);
  const [labels, setLabels] = useState({
    title: "Transferir Atendimento",
    btn: "Transferir",
  });

  useEffect(() => {
    if (open) {
      fetchQueues();
      fetchUsers();
      if (open === "edit") {
        setLabels({
          title: "Editar Filas",
          btn: "Salvar",
        });
        setQueueId(data.data.queue);
        setUserId(data.data.queueUsersId);
        setMoreOptions(data.data.moreOptions);
        setCheckedUser(data.data.checkedUser);
        setAtivarRoteador(data.data.ativarRoteador);
      } else if (open === "create") {
        setLabels({
          title: "Adicionar Fila ao fluxo",
          btn: "Adicionar",
        });
        setQueueId('');
        setUsersList([]);
      }
    }
  }, [open, data]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchQueues = async () => {
    try {
      const response = await api.get("/queue");
      if (isMounted.current) {
        setQueues(response.data);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const fetchUsers = async () => {
    try {
      const  response  = await api.get("/users");
      const userList =  response.data.users;
      setUsersList(userList);
    } catch (err) {
      toastError(err);
    }
  }

  const handleClose = () => {
    close(null);
  };

  const handleSaveNode = async (values) => {
    try {
      if (values.queueId === '') return;
      if (open === "edit") {
        if (values.moreOptions === true) {
          onUpdate({
            ...data,
            data: {
              queue: values.queueId,
              queueUsersId: values.queueUsersId,
              moreOptions: values.moreOptions,
              ativarRoteador: values.ativarRoteador,
              checkedUser: values.checkedUser,
            },
          });
        } else {
          onUpdate({
            ...data,
            data: {
              queue: values.queueId,
              moreOptions: false,
              ativarRoteador: false,
              checkedUser: false
            },
          });
        }
      } else if (open === "create") {
        if (values.moreOptions === true) {

          onSave({
            queue: values.queueId,
            queueUsersId: values.queueUsersId,
            moreOptions: values.moreOptions,
            checkedUser: values.checkedUser,
            ativarRoteador: values.ativarRoteador,
          });

        } else {
          onSave({
            queue: values.queueId,
            queueUsersId: values.queueUsersId,
            moreOptions: values.moreOptions
          });
        }
      }
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog open={Boolean(open)} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle id="form-dialog-title">{labels.title}</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              queueId: queueId,
              moreOptions: moreOptions,
              ativarRoteador: ativarRoteador,
              checkedUser: checkedUser,
              queueUsersId: userId
            }}
            validationSchema={TransferSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSaveNode(values);
              setSubmitting(false);
            }}
            enableReinitialize
          >
            {({ errors, touched, isSubmitting, values, handleChange, setFieldValue }) => (
              <Form>
                <Stack spacing={3}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Fila de Destino</InputLabel>
                    <Field
                      name="queueId"
                      as={Select}
                      label="Fila de Destino"
                      value={values.queueId}
                      onChange={(e) => handleChange(e)}
                      style={selectFieldStyles}
                      error={touched.queueId && !!errors.queueId}
                    >
                      {queues.map((queue) => (
                        <MenuItem key={queue.id} value={queue.id}>
                          {queue.name}
                        </MenuItem>
                      ))}
                    </Field>
                    {errors.queueId && touched.queueId ? (
                      <div>{errors.queueId}</div>
                    ) : null}
                  </FormControl>

                  <FormControlLabel
                    control={
                      <Field
                        as={Switch}
                        color="primary"
                        name="ativarRoteador"
                        checked={values.moreOptions}
                        onChange={(e) => setFieldValue('moreOptions', e.target.checked)}
                      />
                    }
                    label={i18n.t("Opcões adicionais")}
                  />

                  {values.moreOptions === true && (
                    <>
                      <FormControlLabel
                        control={
                          <Field
                            as={Switch}
                            color="primary"
                            name="ativarRoteador"
                            checked={values.ativarRoteador}
                            disabled={values.checkedUser === true ? true : false}
                            onChange={(e) => setFieldValue('ativarRoteador', e.target.checked)}
                          />
                        }
                        label={i18n.t("Randomizar Usuário")}
                      />
                      <FormControlLabel
                        control={
                          <Field
                            as={Switch}
                            color="primary"
                            name="checkedUser"
                            checked={values.checkedUser}
                            disabled={values.ativarRoteador === true ? true : false}
                            onChange={(e) => setFieldValue('checkedUser', e.target.checked)}
                          />
                        }
                        label={i18n.t("Escolher um Usuário")}
                      />
                      {values.checkedUser === true && (
                        <>
                          <InputLabel>{"Selecione um Atendente"}</InputLabel>
                          <Select
                            value={values.queueUsersId}
                            style={{ width: "100%" }}
                            size="small"
                            onChange={(e) => setFieldValue('queueUsersId', e.target.value)}
                          >
                            <MenuItem key={0} value={0}></MenuItem>
                            {usersList.map((u) => (
                              <MenuItem key={u.id} value={u.id}>
                                {u.name}
                              </MenuItem>
                            ))}
                          </Select>

                        </>
                      )}
                    </>
                  )}

                  <DialogActions>
                    <Button onClick={handleClose} color="secondary" variant="outlined">
                      {i18n.t("contactModal.buttons.cancel")}
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      className={classes.btnWrapper}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <CircularProgress size={24} className={classes.buttonProgress} /> : labels.btn}
                    </Button>
                  </DialogActions>
                </Stack>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FlowBuilderQueuesModal;
