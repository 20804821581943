import { allowedPlans } from './plans';
import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from "react";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;



export const validateLicense = async () => {
  try {
    const response = await axios.get(`${BACKEND_URL}/validade`);
    return response.data;
  } catch (error) {
    console.error('Erro ao validar a licença:', error);
    throw error;
  }
};

export const useLicenseChecker = () => {
  const [plano, setPlano] = useState("Verificando...");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkLicense = async () => {
      try {
        const response = await validateLicense();
        const plano = response.plano;

        if (allowedPlans.includes(plano)) {
          setPlano(plano);
        } else {
          setPlano("Inválido");
        }
      } catch (error) {
        console.error('Erro ao verificar a licença:', error);
        setPlano("Erro");
      }
      setLoading(false);
    };

    checkLicense();
  }, []);

  return { plano, loading };
};