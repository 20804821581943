import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Button, Paper, Typography } from '@material-ui/core';
import ModalImageCors from '../../ModalImageCors';
import { GetApp } from '@material-ui/icons';
import { useDate } from '../../../hooks/useDate';

const useStyles = makeStyles((theme) => ({
  messageContainer: {
    display: 'flex',

    justifyContent: props => props.isOwn ? 'flex-end' : 'flex-start',
  },
  message: {
    padding: theme.spacing(1, 2),
    maxWidth: '70%',
    borderRadius: 16,
    backgroundColor: props => props.isOwn ? theme.palette.primary.main : theme.palette.grey[200],
    color: props => props.isOwn ? theme.palette.primary.contrastText : theme.palette.text.primary,
  },
  messageRight: {
    minWidth: 100,
    marginRight: "12px",
    padding: "8px 12px",
    paddingBottom: "26px",
    marginTop: 8,
    width: "fit-content",
    maxWidth: "600px",
    height: "auto",
    display: "block",
    position: "relative",
    borderRadius: "10px",
    background: "#E0F8FF",
    paddingBottom: "8px",
    wordBreak: "break-word",
    borderTopRightRadius: 0,
    "& span": {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "16.8px",
    },

    whiteSpace: "pre-wrap",
    color: "#333E47",
    alignSelf: "flex-end",
  },
  messageLeft: {
    marginRight: 12,
    minWidth: 100,
    padding: "8px 12px",
    paddingBottom: "26px",
    marginTop: 8,
    width: "fit-content",
    maxWidth: "600px",
    height: "auto",
    display: "block",
    position: "relative",
    borderRadius: "10px",
    background: "#F4F4F5",
    borderTopLeftRadius: 0,
    wordBreak: "break-word",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },
    "& span": {
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "16.8px",
    },

    whiteSpace: "pre-wrap",
    color: "#333E47",
    alignSelf: "flex-start",
  },
  timestamp: {
    fontSize: '0.75rem',
    color: theme.palette.text.secondary,
    marginTop: 2,
  },
  messageMedia: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10,
  },
}));

const ChatMessage = ({ message, isOwn }) => {
  const classes = useStyles({ isOwn });
  const { datetimeToClient } = useDate();

  const checkMessageMedia = (message) => {

    if (message.mediaType === "image") {
      return <ModalImageCors imageUrl={message.mediaPath} />;
    }
    if (message.mediaType === "audio") {
      return (
        <audio controls>
          <source src={message.mediaPath} type="audio/ogg"></source>
        </audio>
      );
    }

    if (message.mediaType === "video") {
      return (
        <video
          className={classes.messageMedia}
          src={message.mediaPath}
          controls
        />
      );
    } else {
      return (
        <>
          <div className={classes.downloadMedia}>
            <Button
              startIcon={<GetApp />}
              color="primary"
              variant="outlined"
              target="_blank"
              href={message.mediaPath}
            >
              Download
            </Button>
          </div>
          {/* <Divider /> */}
        </>
      );
    }
  };



  return (
    <div className={classes.messageContainer}>
      <Paper elevation={0} className={isOwn ? classes.messageRight : classes.messageLeft}>
        <Typography variant="body2">{message.mediaPath && checkMessageMedia(message)}</Typography>
        <Typography variant="body2">{message.message}</Typography>
        <Typography className={classes.timestamp}>
          {datetimeToClient(message.createdAt)}
        </Typography>
      </Paper>
    </div>
  );
};

export default ChatMessage;