import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { Badge, Collapse, List, Tooltip } from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListIcon from "@material-ui/icons/ListAlt";
import BusinessIcon from "@material-ui/icons/Business";
import { CalendarToday, LoyaltyRounded } from "@material-ui/icons";
import MailOutlineIcon from "@material-ui/icons/MailOutline"; // Importe o ícone de e-mail
import SendIcon from "@material-ui/icons/Send"; // Importe o ícone de enviar
import ScheduleIcon from "@material-ui/icons/Schedule";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import Typography from "@material-ui/core/Typography";
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { isArray } from "lodash";
import api from "../services/api";
import toastError from "../errors/toastError";
import usePlans from "../hooks/usePlans";
import useVersion from "../hooks/useVersion";
import { ShapeLine } from "@mui/icons-material";
import RotateRight from "@material-ui/icons/RotateRight";
import { useLicenseChecker } from "../license/validateLicense";
import {
  PieChart,
  MessageCircle,
  KanbanSquare,
  MessagesSquare,
  Bookmark,
  HelpCircle,
  ListChecks,
  Users,
  CalendarPlus,
  Zap,
  TrendingUp,
  Settings2,
  Contact,
  ListTodo,
  BookOpen,
  MonitorCheck,
  Workflow,
  UserPlus,
  PlugZap,
  Landmark,
  Settings,
  ClipboardCheck,
  MessageSquare,
  Star,
} from "lucide-react";
import { makeStyles } from "@material-ui/core/styles";
import { SocketContext } from "../context/Socket/SocketContext";

const useStyles = makeStyles((theme) => ({
  ListSubheader: {
    height: 26,
    marginTop: "-15px",
    marginBottom: "20px",
  },
  listItem: {
    borderRadius: 10,
    margin: "5px 0",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
  subMenu: {
    paddingLeft: 15,
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  iconColor: {
    color: theme.palette.primary.main, // Usando a cor primária do tema como exemplo
  },
  logoutButton: {
    borderRadius: 10,
    marginTop: 10,
    backgroundColor: theme.palette.error.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));


function ListItemLink(props) {
  const { icon, primary, to, className } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem dense button component={renderLink} className={className}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const reducer = (state, action) => {
  if (action.type === "LOAD_CHATS") {
    const chats = action.payload;
    const newChats = [];

    if (isArray(chats)) {
      chats.forEach((chat) => {
        const chatIndex = state.findIndex((u) => u.id === chat.id);
        if (chatIndex !== -1) {
          state[chatIndex] = chat;
        } else {
          newChats.push(chat);
        }
      });
    }

    return [...state, ...newChats];
  }

  if (action.type === "UPDATE_CHATS") {
    const chat = action.payload;
    const chatIndex = state.findIndex((u) => u.id === chat.id);

    if (chatIndex !== -1) {
      state[chatIndex] = chat;
      return [...state];
    } else {
      return [chat, ...state];
    }
  }

  if (action.type === "DELETE_CHAT") {
    const chatId = action.payload;

    const chatIndex = state.findIndex((u) => u.id === chatId);
    if (chatIndex !== -1) {
      state.splice(chatIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }

  if (action.type === "CHANGE_CHAT") {
    const changedChats = state.map((chat) => {
      if (chat.id === action.payload.chat.id) {
        return action.payload.chat;
      }
      return chat;
    });
    return changedChats;
  }
};

const MainListItems = (props, { collapsed }) => {
  const classes = useStyles();
  const { drawerClose } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user, handleLogout } = useContext(AuthContext);
  const socketManager = useContext(SocketContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [openCampaignSubmenu, setOpenCampaignSubmenu] = useState(false);
  const [showCampaigns, setShowCampaigns] = useState(false);
  // novas features
  const [showSchedules, setShowSchedules] = useState(false);
  const [showInternalChat, setShowInternalChat] = useState(false);
  const [showExternalApi, setShowExternalApi] = useState(false);
  const [useShowFlowBuilder, setUseShowFlowBuilder] = useState(false);
  const [openKanbanSubmenu, setOpenKanbanSubmenu] = useState(false);
  const [openEmailSubmenu, setOpenEmailSubmenu] = useState(false);
  const [openEvaluationSubmenu, setOpenEvaluationSubmenu] = useState(false);
  const { plano } = useLicenseChecker();
  const history = useHistory();
  const [invisible, setInvisible] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam] = useState("");
  const [chats, dispatch] = useReducer(reducer, []);
  const [version, setVersion] = useState(false);
  const { getPlanCompany } = usePlans();
  const { getVersion } = useVersion();

  const handleClickLogout = () => {
    //handleCloseMenu();
    handleLogout();
  };

  useEffect(() => {
    async function fetchVersion() {
      const _version = await getVersion();
      setVersion(_version.version);
    }
    fetchVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    async function fetchData() {
      const planConfigs = await getPlanCompany(undefined, user.companyId);
      setShowCampaigns(planConfigs.plan.useCampaigns);
      setShowSchedules(planConfigs.plan.useSchedules);
      setShowInternalChat(planConfigs.plan.useInternalChat);
      setShowExternalApi(planConfigs.plan.useExternalApi);
      setUseShowFlowBuilder(planConfigs.plan.useShowFlowBuilder);
    }
    fetchData();
  }, [user]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchChats();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const socket = socketManager.getSocket(user.companyId);
    const onCompanyChat = (data) => {
      if (data.action === "new-message") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
      if (data.action === "update") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
    };
    socket.on(`company-${user.companyId}-chat`, onCompanyChat);

    return () => {
      socket.off(`company-${user.companyId}-chat`, onCompanyChat);
    };
  }, [socketManager, user]);

  useEffect(() => {
    let unreadsCount = 0;
    if (chats.length > 0) {
      for (let chat of chats) {
        for (let chatUser of chat.users) {
          if (chatUser.userId === user.id) {
            unreadsCount += chatUser.unreads;
          }
        }
      }
    }
    if (unreadsCount > 0) {
      setInvisible(false);
    } else {
      setInvisible(true);
    }
  }, [chats, user.id]);

  // useEffect(() => {
  //   if (localStorage.getItem("cshow")) {
  //     setShowCampaigns(true);
  //   }
  // }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  const fetchChats = async () => {
    try {
      const { data } = await api.get("/chats/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_CHATS", payload: data.records });
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div onClick={drawerClose}>
      <Can
        role={user.profile}
        perform="dashboard:view"
        yes={() => (
          <ListItemLink to="/dashboard" primary="Dashboard" icon={<PieChart className={classes.iconColor} />} />
        )}
      />

      <ListItemLink
        to="/tickets"
        primary={("Atendimento")}
        icon={<MessageCircle className={classes.iconColor} />}
      />

      {(plano === "basico" || plano === "empresarial" || plano === "ultimate") && (
        <ListItem button onClick={() => setOpenEmailSubmenu((prev) => !prev)}>
          <ListItemIcon>
            <MailOutlineIcon className={classes.iconColor} />
          </ListItemIcon>
          <ListItemText primary={i18n.t("Email")} />
          {openEmailSubmenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
      )}

      <Collapse in={openEmailSubmenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            onClick={() => history.push("/Email")}
            button
            style={{ paddingLeft: 15 }}
          >
            <ListItemIcon>
              <SendIcon className={classes.iconColor} />
            </ListItemIcon>
            <ListItemText primary={i18n.t("Enviar")} />
          </ListItem>
          <ListItem
            onClick={() => history.push("/EmailLis")}
            button
            style={{ paddingLeft: 15 }}
          >
            <ListItemIcon>
              <EventIcon className={classes.iconColor} />
            </ListItemIcon>
            <ListItemText primary={i18n.t("Enviados")} />
          </ListItem>
          {/* Adicione aqui a nova rota para agendamento de e-mails */}
          <ListItem
            onClick={() => history.push("/EmailScheduler")}
            button
            style={{ paddingLeft: 15 }}
          >
            <ListItemIcon>
              <ScheduleIcon className={classes.iconColor} />
            </ListItemIcon>
            <ListItemText primary={i18n.t("Agendar")} />
          </ListItem>
          {/* Adicione aqui a nova rota para e-mails agendados */}
          <ListItem
            onClick={() => history.push("/EmailsAgendado")}
            button
            style={{ paddingLeft: 15 }}
          >
            <ListItemIcon>
              <ScheduleIcon className={classes.iconColor} /> {/* Ícone apropriado para agendamento */}
            </ListItemIcon>
            <ListItemText primary={i18n.t("Agendados")} />{" "}
            {/* Nome apropriado para a nova rota */}
          </ListItem>
        </List>
      </Collapse>


      {(plano === "basico" || plano === "empresarial" || plano === "ultimate") && (
        <ListItem button onClick={() => setOpenKanbanSubmenu((prev) => !prev)}>
          <ListItemIcon>
            <KanbanSquare className={classes.iconColor} />
          </ListItemIcon>
          <ListItemText primary={i18n.t("Kanban")} />
          {openKanbanSubmenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
      )}

      <Collapse
        style={{ paddingLeft: 15 }}
        in={openKanbanSubmenu}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          <ListItem onClick={() => history.push("/kanban")} button>
            <ListItemIcon>
              <ListIcon className={classes.iconColor} />
            </ListItemIcon>
            <ListItemText primary={i18n.t("Painel")} />
          </ListItem>
          <ListItem onClick={() => history.push("/tagsKanban")} button>
            <ListItemIcon>
              <CalendarToday className={classes.iconColor} />
            </ListItemIcon>
            <ListItemText primary={i18n.t("Tags")} />
          </ListItem>
          <ListItem onClick={() => history.push("/campanhas")} button>
            <ListItemIcon>
              <EventAvailableIcon className={classes.iconColor} />
            </ListItemIcon>
            <ListItemText primary={i18n.t("Em Andamento")} />
          </ListItem>
        </List>
      </Collapse>



      <ListItemLink
        to="/quick-messages"
        primary={i18n.t("mainDrawer.listItems.quickMessages")}
        icon={<Zap className={classes.iconColor} />}
      />


      <ListItemLink
        to="/contacts"
        primary={i18n.t("mainDrawer.listItems.contacts")}
        icon={<Users className={classes.iconColor} />}
      />

      <ListItemLink
        to="/todolist"
        primary={i18n.t("Tarefas")}
        icon={<ListChecks className={classes.iconColor} />}
      />
      {/*
        <ListItemLink
          to="/Calendario"
          primary={i18n.t("Calendario")}
          icon={<CalendarToday />}
        />
        */}

      {showSchedules && (
        <>
          <ListItemLink
            to="/schedules"
            primary={i18n.t("mainDrawer.listItems.schedules")}
            icon={<CalendarPlus className={classes.iconColor} />}
          />
        </>
      )}

      <ListItemLink
        to="/tags"
        primary={i18n.t("mainDrawer.listItems.tags")}
        icon={<Bookmark className={classes.iconColor} />}
      />

      {showInternalChat && (plano === "basico" || plano === "empresarial" || plano === "ultimate") && (
        <ListItemLink
          to="/chats"
          primary={i18n.t("mainDrawer.listItems.chats")}
          icon={
            <Badge
              color="secondary"
              variant="dot"
              invisible={invisible}
              overlap="rectangular"
            >
              <MessagesSquare className={classes.iconColor} />
            </Badge>
          }
        />
      )}

      {(plano === "basico" || plano === "empresarial" || plano === "ultimate") && (
        <ListItemLink
          to="/ChatGPT"
          primary={i18n.t("ChatGPT")}
          icon={<LiveHelpIcon className={classes.iconColor} />}
        />
      )}

      <ListItemLink
        to="/helps"
        primary={i18n.t("mainDrawer.listItems.helps")}
        icon={<HelpCircle className={classes.iconColor} />}
      />

      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <Divider />
            {/*<ListSubheader inset>
              {i18n.t("mainDrawer.listItems.administration")}
            </ListSubheader>*/ }
            {showCampaigns && (
              <>
                <ListItem
                  dense
                  button
                  onClick={() => setOpenCampaignSubmenu((prev) => !prev)}
                >
                  <ListItemIcon>
                    <TrendingUp className={classes.iconColor} />
                  </ListItemIcon>
                  <ListItemText
                    primary={i18n.t("mainDrawer.listItems.campaigns")}
                  />
                  {openCampaignSubmenu ? (
                    <ExpandLessIcon className={classes.iconColor} />
                  ) : (
                    <ExpandMoreIcon className={classes.iconColor} />
                  )}
                </ListItem>
                <Collapse
                  style={{ paddingLeft: 15 }}
                  in={openCampaignSubmenu}
                  timeout="auto"
                  unmountOnExit
                >
                  <List dense component="div" disablePadding>
                    <ListItem onClick={() => history.push("/campaigns")} button>
                      <ListItemIcon>
                        <ListTodo className={classes.iconColor} />
                      </ListItemIcon>
                      <ListItemText primary="Listagem" />
                    </ListItem>
                    <ListItem
                      onClick={() => history.push("/contact-lists")}
                      button
                    >
                      <ListItemIcon>
                        <Contact className={classes.iconColor} />
                      </ListItemIcon>
                      <ListItemText primary="Listas de Contatos" />
                    </ListItem>
                    <ListItem
                      onClick={() => history.push("/campaigns-config")}
                      button
                    >
                      <ListItemIcon>
                        <Settings2 className={classes.iconColor} />
                      </ListItemIcon>
                      <ListItemText primary="Configurações" />
                    </ListItem>
                  </List>
                </Collapse>
              </>
            )}

            {user.super && (
              <ListItemLink
                to="/announcements"
                primary={i18n.t("mainDrawer.listItems.annoucements")}
                icon={<BookOpen className={classes.iconColor} />}
              />

            )}

            {/*
            {user.super && (
              <ListItemLink
                to="/campanhaAvancada"
                primary={i18n.t("Campanha Avancada")}
                icon={<PlaylistAddCheckIcon />}
              />
            )}

            */}


            {showExternalApi && (
              <>
                <ListItemLink
                  to="/messages-api"
                  primary={i18n.t("mainDrawer.listItems.messagesAPI")}
                  icon={<PlugZap className={classes.iconColor} />}
                />
              </>
            )}

            <ListItemLink
              to="/users"
              primary={i18n.t("mainDrawer.listItems.users")}
              icon={<UserPlus className={classes.iconColor} />}
            />
            <ListItemLink
              to="/queues"
              primary={i18n.t("mainDrawer.listItems.queues")}
              icon={<Workflow className={classes.iconColor} />}
            />

            {useShowFlowBuilder && plano === "ultimate" && (
              <ListItemLink
                to="/flowbuilders"
                primary={i18n.t("FlowBuilder")}
                icon={<ShapeLine className={classes.iconColor} />}
              />
            )}
            <ListItemLink
              to="/connections"
              primary={i18n.t("mainDrawer.listItems.connections")}
              icon={
                <Badge
                  badgeContent={connectionWarning ? "!" : 0}
                  color="error"
                  overlap="rectangular"
                >
                  <MonitorCheck className={classes.iconColor} />
                </Badge>
              }
            />

            {/* Avaliação Dropdown Menu */}
            <ListItem
              dense
              button
              onClick={() => setOpenEvaluationSubmenu((prev) => !prev)}
            >
              <ListItemIcon>
                <Star className={classes.iconColor} />
              </ListItemIcon>
              <ListItemText primary="Avaliação" />
              {openEvaluationSubmenu ? (
                <ExpandLessIcon className={classes.iconColor} />
              ) : (
                <ExpandMoreIcon className={classes.iconColor} />
              )}
            </ListItem>
            <Collapse
              style={{ paddingLeft: 15 }}
              in={openEvaluationSubmenu}
              timeout="auto"
              unmountOnExit
            >
              <List dense component="div" disablePadding>
                <ListItem onClick={() => history.push("/ratings")} button>
                  <ListItemIcon>
                    <ClipboardCheck className={classes.iconColor} />
                  </ListItemIcon>
                  <ListItemText primary="Cadastrar" />
                </ListItem>
                <ListItem
                  onClick={() => history.push("/assessmentReport")}
                  button
                >
                  <ListItemIcon>
                    <MessageSquare className={classes.iconColor} />
                  </ListItemIcon>
                  <ListItemText primary="Feedbacks" />
                </ListItem>
              </List>
            </Collapse>

            {/* Commented out Integrations menu item
            {(plano === "basico" || plano === "empresarial" || plano === "ultimate") && (
              <ListItemLink
                to="/integrations"
                primary={"Integrações"}
                icon={<AddToQueueRounded className={classes.iconColor} />}
              />
            )}
            */}

            {(plano === "empresarial" || plano === "ultimate") && (
              <ListItemLink
                to="/financeiro"
                primary={i18n.t("mainDrawer.listItems.financeiro")}
                icon={<Landmark className={classes.iconColor} />}
              />
            )}
            <ListItemLink
              to="/settings"
              primary={i18n.t("mainDrawer.listItems.settings")}
              icon={<Settings className={classes.iconColor} />}
            />

            {user.super && (plano === "basico" || plano === "empresarial" || plano === "ultimate") && (
                <ListItemLink
                  to="/companies"
                  primary={i18n.t("mainDrawer.listItems.companies")}
                  icon={<BusinessIcon className={classes.iconColor} />}
                />
            )}
            {user.super && !collapsed && (
              <React.Fragment>
                <Divider />
                <Typography
                  style={{
                    fontSize: "12px",
                    padding: "10px",
                    textAlign: "right",
                    fontWeight: "bold",
                  }}
                >
                  V: {version} - {plano}
                </Typography>
              </React.Fragment>
            )}
          </>
        )}
      />
      <Divider />
      <li>
        <ListItem
          button
          dense
          onClick={handleClickLogout}
          className={classes.logoutButton}
        >
          <ListItemIcon>
            <RotateRight />
          </ListItemIcon>
          <ListItemText primary={i18n.t("Sair")} />
        </ListItem>
      </li>
    </div>
  );
};

export default MainListItems;